/*.cheq {
	display: block;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 100%;
}
.cheq input {
	float: left;
	opacity: 0;
	cursor: pointer;
}
.cheq:hover input ~ .checkmark {
	background-color: #ccc;
}
.cheq input:checked ~ .checkmark {
	background-color: #2196f3;
}
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}
.cheq input:checked ~ .checkmark:after {
	display: block;
}
.cheq .checkmark:after {
	left: 6px;
	top: 0px;
	width: 9px;
	height: 18px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.cheq-label {
	float: left;
	font-weight: 600;
	margin-left: 35px;
	margin-top: 3px;
	font-size: 14px;
}
.checkbox label,
.radio label {
	padding-left: 0px !important;
}

.containercheq {
	display: block;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	float: left;
}
.containercheq input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	float: left;
}
.checkmark {
	margin-left: 5px;
	margin-top: 0px;
	position: absolute;
	height: 25px;
	width: 25px;
	border-style: solid;
	border-width: 2px;
	border-color: #3c8dbc;
	float: left;
}
.containercheq:hover input ~ .checkmark {
	background-color: #ccc;
}
.containercheq input:checked ~ .checkmark {
	background-color: #3c8dbc;
}
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}
.containercheq input:checked ~ .checkmark:after {
	display: block;
}
.containercheq .checkmark:after {
	left: 6px;
	top: 0px;
	width: 9px;
	height: 18px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}*/

.form-check-label {
	margin-top: 3px;
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check{
    display:block;
    min-height:1.5rem;
    padding-left: 0;
    margin-bottom:.125rem
}
.form-check-input[type=checkbox]{
    width: 25px;
    height: 25px;
    margin-top:.25em;
    vertical-align:top;
    background-color:#fff;
    background-repeat:no-repeat;
    background-position:50%;
    background-size:contain;
    border:1px solid rgba(0,0,0,.25);
    -webkit-appearance:none;
    appearance:none;
    -webkit-print-color-adjust:exact;
    color-adjust:exact
}
.form-check-input[type=checkbox]{
    border-radius:.25em
}
/*.form-check-input[type=radio]{
    border-radius:50%
}*/
.form-check-input:active{
    filter:brightness(90%)
}
/*.form-check-input:focus{
    border-color:#86b7fe;
    outline:0;
    box-shadow:0 0 0 .25rem rgba(13,110,253,.25)
}*/
.form-check-input:checked{
    background-color:#0d6efd;
    border-color:#0d6efd
}
.form-check-input:checked[type=checkbox]{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E")
}
.form-check-input:checked[type=radio]{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
}
.form-check-input[type=checkbox]:indeterminate{
    background-color:#0d6efd;
    border-color:#0d6efd;
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E")
}
.form-check-input:disabled{
    pointer-events:none;
    filter:none;
    opacity:.5
}
.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label{
    opacity:.5
}
